import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import CheckboxInput from '@/components/form/CheckboxInput';
import TextInput from '@/components/form/TextInput';
import { useFormik } from 'formik';
import { graphql, Link, PageProps, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import * as React from 'react';
import { FC, useState } from 'react';
import { useBoolean } from 'react-use';
import * as Yup from 'yup';

const ProductReviewsLandingPage: FC<PageProps> = () => {
  // const isMobile = useIsMobile();
  const [success, setSuccess] = useBoolean(false);
  const [error, setError] = useState<string>();

  const formik = useFormik({
    initialValues: {
      email: '',
      toc: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please provide your email address'),
      toc: Yup.boolean()
        .isTrue('You must accept T&C')
        .required('You must accept T&C'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSuccess(false);
      setError(undefined);
      setSubmitting(true);

      try {
        const formData = new URLSearchParams();

        formData.append('email', values.email);

        formData.append('start_day', '0'); // set autoresponder to 0
        formData.append('campaign_token', 'r21og'); // OptiOffer Product Reviews Feature - RO
        formData.append('webform[consent#honK-ver#zqHo]', `${values.toc}`); // T&C consent field

        await fetch('https://app.getresponse.com/add_subscriber.html', {
          mode: 'no-cors',
          method: 'POST',
          body: formData,
        });

        setSuccess(true);

        // setTimeout(() => {
        //   navigate('/');
        // }, 2000);
      } catch (ex) {
        setError(ex?.toString());
      }

      setSubmitting(false);
    },
  });

  const { bgImage } = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "lp/product-reviews.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
  `);

  return (
    <Layout className="relative" hideHeader minimalFooter>
      <SEO
        url="lp/reseller-reviews"
        title="Nu mai cumpara echipamente noi fara sa discuti cu cei care le folosesc zilnic"
        description=""
        disableIndex
      />
      <BackgroundImage
        Tag="section"
        className="h-screen flex flex-col"
        {...convertToBgImage(getImage(bgImage))}
      >
        <div className="w-full px-11 py-4 text-base text-white">OPTIOFFER</div>
        <div className="flex-1 flex flex-col p-4 pb-6 items-center lg:justify-center">
          <div className="flex-1 lg:flex-initial flex flex-col justify-center items-center p-4 text-white lg:mb-10">
            <div className="text-4xl lg:text-5xl font-semibold text-center lg:leading-snug mb-2 lg:max-w-4xl">
              Nu mai cumpara echipamente noi fara sa discuti cu cei care le
              folosesc zilnic
            </div>
            <div className="lg:text-lg text-center leading-5 lg:max-w-4xl">
              Fa o vizita patronului sau bucatarului care foloseste produsul de
              care esti interesat si cere-i parerea personal, in timp ce-l vezi
              in functiune.
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col lg:max-w-3xl w-full"
          >
            {success ? (
              <div id="OptInSuccess" className="text-white text-center text-xl">
                Multumim pentru inregistrare, revenim in cel mai scurt timp cu
                un mesaj.
              </div>
            ) : (
              <>
                <div className="flex flex-col lg:flex-row lg:w-full space-y-5 lg:space-x-5 lg:space-y-0">
                  <TextInput
                    formik={formik}
                    name="email"
                    placeholder="eg: exemplu@exemplu.com"
                    className="lg:flex-1"
                    innerClassName="h-10"
                  />

                  <button
                    type="submit"
                    className="flex items-center justify-center px-4 py-2 h-10 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-yellow mb-1"
                    disabled={formik.isSubmitting}
                  >
                    Inscrie-te acum!
                  </button>
                </div>

                <CheckboxInput
                  formik={formik}
                  name="toc"
                  className="text-white text-xl lg:text-base"
                >
                  Accept
                  <Link to="/terms-and-conditions/" className="ml-1 underline">
                    Termenii si Conditiile
                  </Link>
                </CheckboxInput>

                {error && (
                  <div
                    id="OptInError"
                    className="text-error text-center text-base mt-4"
                  >
                    {error}
                  </div>
                )}
              </>
            )}
          </form>
        </div>
      </BackgroundImage>
    </Layout>
  );
};

export default ProductReviewsLandingPage;
